import React from "react"
import { graphql, Link } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { ProcessWrap, FirstProcess, ProcessNumber, OtherProcess } from "../components/ProcessStyle"
import styled from "styled-components"
import {
  Section,
  BreadCrumb,
  Background,
  SectionPageTitle,
  MarkdownContent,
  Container,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import Bg from "../components/Image/IndustryHeroBg"
import CheckIcon from "../components/Icons/CheckIcon"
import Mask from "../components/Mask"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import BreakpointUp from "../components/Media/BreakpointUp"

const ProcessRow = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;

  &.even {
    .step-number {
      left: -5%;
      ${BreakpointUp.xxl`
				left:-9%;
			`}
    }
  }
  &.odd {
    flex-direction: column-reverse;
    ${BreakpointUp.md`
			flex-direction:row;
		`}
    .step-number {
      right: -5%;
      ${BreakpointUp.xxl`
				right:-9%;
			`}
    }
  }
`
const StepNumber = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: 'UniNeueBold', sans-serif;
  font-weight: 800;
  color: rgba(0, 89, 178, 0.1);
  display: none;
  ${BreakpointUp.xl`
		display:block;
		font-size: 100px;
		line-height: 120px;
	`}
  ${BreakpointUp.xxl`
		font-size: 150px;
		line-height: 125px;
	`}
`
const ProcessContent = styled.div`
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.md`
		flex: 0 0 58.333333%;
		max-width: 58.333333%;
	`}
  h2 {
    margin-bottom: 5px;
  }
`
const ProcessImage = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  border-radius: 8px;
  margin-bottom: 40px;
  ${BreakpointUp.md`
		flex: 0 0 41.666667%;
		max-width: 41.666667%;
		margin-bottom:0;
	`}
  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`
const Figure = styled.div`
  position: relative;
  margin: 0;
`

const Divider1 = styled.div`
  width: 50%;
  margin: auto;
  display: ${(props) => props.display};
  & div:nth-child(1) {
    height: 50px;
    border-right: 1px solid #bad4eb;
    border-right-style: dashed;
  }
  & div:nth-child(2) {
    height: 1px;
    border-bottom: 1px solid #bad4eb;
    border-bottom-style: dashed;
  }
  & div:nth-child(3) {
    height: 50px;
    border-left: 1px solid #bad4eb;
    border-left-style: dashed;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
const Divider2 = styled.div`
  width: 50%;
  margin: auto;
  & div:nth-child(1) {
    height: 50px;
    border-left: 1px #bad4eb;
    border-left-style: dashed;
  }
  & div:nth-child(2) {
    height: 1px;
    border-bottom: 1px solid #bad4eb;
    border-bottom-style: dashed;
  }
  & div:nth-child(3) {
    height: 50px;
    border-right: 1px solid #bad4eb;
    border-right-style: dashed;
  }
  @media (max-width: 767px) {
    display: none;
  }
`
class ProcessPage extends React.Component {
  render() {
    const pageData = this.props.data.allContentfulProcess
    return (
      <Layout>
        <Seo title={this.props.data.contentfulProcessListPage.metaTitle} description={this.props.data.contentfulProcessListPage.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section xpt="120px" xpb="80px" mpt="120px" mpb="80px" pt="90px" pb="60px" bgColor="#EDF8FF">
          <Background right="0" height="inherit"><Bg /></Background>
          <Container>
            <BreadCrumb>
              <Link to="/"><span>Home</span></Link> AES Repair Process
            </BreadCrumb>
            <SectionPageTitle>AES Repair Process</SectionPageTitle>
            <ProcessWrap>
              {pageData.edges.map((item, i) => {
                if (i === 0) {
                  return (
                    <FirstProcess key={i}>
                      <strong className="h3">{item.node.processTitle}</strong>
                      <p>{item.node.summary.summary}</p>
                      <ProcessNumber borderColor="rgba(250, 183, 2, 0.2)">
                        {i + 1}
                      </ProcessNumber>
                    </FirstProcess>
                  )
                } else {
                  return (
                    <OtherProcess key={i}>
                      <strong className="h3">{item.node.processTitle}</strong>
                      <p>{item.node.summary.summary}</p>
                      <ProcessNumber borderColor="rgba(250, 183, 2, 0.2)">
                        {i + 1}
                      </ProcessNumber>
                    </OtherProcess>
                  )
                }
              })}
              <CheckIcon fill="#fab702" />
            </ProcessWrap>
          </Container>
        </Section>
        <Section xpt="80px" xpb="80px" mpt="60px" mpb="60px" pt="30px" pb="30px">
          <Container maxWidth="1120px">
            {pageData.edges.map((item, i) => {
              if (i % 2 === 0) {
                return (
                  <div>
                    <ProcessRow key={i} className="even">
                      <StepNumber className="step-number">{i + 1}</StepNumber>
                      <ProcessContent>
                        <h2>{item.node.processTitle}</h2>
                        <MarkdownContent
                          dangerouslySetInnerHTML={{
                            __html:
                              item.node.description.childMarkdownRemark.html,
                          }}
                        />
                      </ProcessContent>
                      <ProcessImage>
                        <Figure>
                          <Mask />
                          <GatsbyImage
                            image={getImage(item.node.image)}
                            alt="AES"
                          />
                        </Figure>
                      </ProcessImage>
                    </ProcessRow>
                    <Divider1 display={i === 4 ? "none" : "block"}>
                      <div />
                      <div />
                      <div />
                    </Divider1>
                  </div>
                )
              } else {
                return (
                  <div>
                    <ProcessRow key={i} className="odd">
                      <ProcessImage>
                        <Figure>
                          <Mask />
                          <GatsbyImage
                            image={getImage(item.node.image)}
                            alt="AES"
                          />
                        </Figure>
                      </ProcessImage>
                      <ProcessContent>
                        <h2>{item.node.processTitle}</h2>
                        <MarkdownContent
                          dangerouslySetInnerHTML={{
                            __html:
                              item.node.description.childMarkdownRemark.html,
                          }}
                        />
                      </ProcessContent>
                      <StepNumber className="step-number">{i + 1}</StepNumber>
                    </ProcessRow>
                    <Divider2>
                      <div />
                      <div />
                      <div />
                    </Divider2>
                  </div>
                )
              }
            })}
          </Container>
        </Section>
        <EmergencyEstimateCTA />
      </Layout>
    )
  }
}

export default ProcessPage

export const pageQuery = graphql`
  query ProcessPageQuery {
    contentfulProcessListPage {
      metaTitle
      metaDescription
      metaImage {
        file {
          url
        }
      }
      heroTitle
    }
    allContentfulProcess(sort: { fields: step }) {
      edges {
        node {
          step
          processTitle
          summary {
            summary
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 100)
          }
        }
      }
    }
  }
`
